.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 400px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  top: 100%;
  left: 0;
}

/* Links inside the dropdown */
.dropdown-content li a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content li a:hover {
  background-color: #ffc107;
}

/* Show the dropdown menu when hovering */
.dropdown-content.show {
  display: block;
}

.addresses-container {
  display: flex;
  gap: 2rem; /* Adjust spacing between the two addresses */
  flex-wrap: wrap; /* Allows wrapping if the container is too narrow */
}

.address-wrapper {
  flex: 1; /* Makes sure each address takes equal space */
  min-width: 300px; /* Ensures a minimum width for each address section */
}

.address-wrapper h3 {
  margin-bottom: 0.5rem; /* Adjust the spacing as needed */
}

.address {
  display: flex;
  flex-direction: column; /* Align address parts vertically */
  gap: 0.5rem; /* Adjust the spacing between address parts */
}

.address p {
  margin: 0;
  padding: 0 0.5rem; /* Add padding if needed for better spacing */
  border-right: 1px solid #ddd; /* Add a separator line */
}

.address p:last-child {
  border-right: none; /* Remove separator line from the last element */
}
.panel-body ul{
  padding-left:20px;
}
.panel-body ul li{
  list-style-type:disc;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
