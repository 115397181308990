.nested-dropdown-btn>ul {
  display: none;
}

.nested-dropdown-btn:hover>ul {
  display: block;
}

.nested-dropdown-btn {
  position: relative;
}

.nested-dropdown-btn>ul {
  width: 175px;
  position: absolute;
  top: 0;
  left: 100%;
  /* Position to the right of the parent */
  z-index: 1000;
  /* Adjust z-index as needed */
  background-color: #151515;
  /* Background color for the submenu */
  border: 1px solid #ccc;
  /* Border for the submenu */
}

.nested-dropdown-btn>ul li {
  display: block;
}


/* Show the nested submenu items on hover */
.nested-dropdown-btn>ul li:hover {
  color: #f0f0f0;
  /* Hover background color */
}

li.nested-dropdown-btn ul li a {
  color: white;
}


.cursor-pointer {
  cursor: pointer;
}

.button-container {
  display: flex;
  justify-content: center;
  /* Align buttons horizontally */
}

.button-row {
  display: flex;
  justify-content: space-evenly;
  /* Equal spacing between buttons */
  width: 100%;
  /* Ensure buttons take up full width */
}

.custom-btn {
  margin-top: 10px;
  margin-bottom: 10px;
  /* Add some margin to the top of the buttons */
}

.offer-action {
  text-align: center;
  background: #020202 none repeat scroll 0 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  margin: 25px auto 0;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.offer-action a {
  display: inline-block;
  padding: 7px 15px;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  width: 47%;
}

.offer-action:after {
  position: absolute;
  content: "";
  width: 66%;
  height: 100%;
  background: #ffc107 none repeat scroll 0 0;
  right: -36px;
  z-index: -1;
  -webkit-transform: skewX(40deg);
  transform: skewX(40deg);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.shop-item {
  box-shadow: 3px 0 29px rgba(0, 0, 0, 0.26);
  transition: box-shadow 0.3s ease;
}

.shop-item:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

}

.item-sorting {
  box-shadow: 3px 0 29px rgba(0, 0, 0, 0.26);
  /* Adjust the values as needed */
  transition: box-shadow 0.3s ease;
}

.item-sorting:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  /* Adjust the values as needed */
}



.fa {
  display: inline-block;
  color: rgb(56, 53, 53);
  font-size: 1.5rem;
  cursor: pointer;
}

.fa:hover {
  color: #f8f9fa;
  /* Lighter red color on hover */
}

.icon-container {
  margin-top: 0;
}

.custom-card {
  background-color: #ffc107;
  margin-top: 1rem;
}

.container-with-shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.container-shadow {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.section-title {
  margin-top: 1rem;
  /* Adjust the value as needed */
}

.card-text i {
  font-size: 15px;
  /* Change the size of the icons */
  margin-right: 8px;
  /* Add some spacing between the icon and the text */
  color: #ffc107;
  /* Change the color of the icons */
}

.card-text i:hover {
  color: rgb(56, 53, 53);

}

.address i {
  font-size: 20px;
  /* Change the size of the icons */
  margin-right: 8px;
  /* Add some spacing between the icon and the text */
  color: #ffc107;
  /* Change the color of the icons */
}

.single-contact-btn {
  margin-left: 30px;
  display: inline-block;


}

.single-contact-btn a {
  display: inline-block;
  margin-bottom: 5px;
  padding: 10px 16px;
  text-transform: lowercase;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 500;
  background: #ffc107 none repeat scroll 0 0;
  border-radius: 4px;
}

.p-description {
  margin-bottom: 5px;
}

.panel-title>a:hover {
  color: black
}

#footer-section {
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 9;
}


@media screen and (min-width: 320px) {
  h4.panel-title {
    font-size: 15px;
  }
}

@media screen and (min-width: 768px) {
  h4.panel-title {
    font-size: 18px;
  }
}

@media screen and (min-width: 1024px) {
  h4.panel-title {
    font-size: 20px;
  }
}